import 'focus-visible';
import create from '@verndale/core';
import modules from './modules';

const isStorybook = !!document.getElementById('storybook-root');
const body = document.querySelector('body') as HTMLBodyElement;

const smoothScroll = () => {
  const scrollLinks = document.querySelectorAll('[data-anchor]');
  scrollLinks.forEach(link => {
    link.addEventListener('click', e => {
      e.preventDefault();
      const id = (link as HTMLElement).dataset.anchor;
      document.querySelector(`${id}`)?.scrollIntoView({ behavior: 'smooth' });
    });
  });
};

document.addEventListener(
  'DOMContentLoaded',
  async () => {
    if (!body.dataset.modulesLoaded || isStorybook) {
      create(modules).then(() => {
        body.dataset.modulesLoaded = 'true';
      });
      smoothScroll();
    }
  },
  false
);
