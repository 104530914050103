import type { Organism } from '@verndale/core';
import globModules from './glob-modules';

const modules: Organism[] = [
  {
    name: 'header',
    loader: () => import('./modules/header')
  },
  {
    name: 'logoWall',
    styles: () => import('../scss/modules/logo-wall.scss')
  },
  {
    name: 'ctaGrid',
    styles: () => import('../scss/modules/cta-grid.scss')
  },
  {
    name: 'contentWithIconCards',
    styles: () => import('../scss/modules/content-with-icon-cards.scss')
  },
  {
    name: 'contentWithImage',
    styles: () => import('../scss/modules/content-with-image.scss')
  },
  {
    name: 'footer',
    styles: () => import('../scss/modules/footer.scss')
  }
];

export default [...globModules, ...modules];
